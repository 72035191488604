import React, { lazy } from 'react';
import { MobileView, BrowserView, isMobileSafari, isChrome } from 'react-device-detect';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

const GhostieDesktop = lazy(() => import('./ghostie-desktop'));
const GhostieApp = lazy(() => import('./ghostie-app'));
const GhostieCameraScreen = lazy(() => import('./ghostie-camerascreen'));
const GhostieAdmin = lazy(() => import('./admin/ghostie-admin'));

export const GhostieIndex = function(props)
{
    const isAcceptedBrowser = isMobileSafari || isChrome;
    if ( isAcceptedBrowser )
    {
        return (
            <>
                <BrowserView>
                    <GhostieDesktop/>
                </BrowserView>
                <MobileView>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/camera/:campaignid/:questid" element={<GhostieCameraScreen/>} />
                            <Route path="/admin/*" element={<GhostieAdmin/>}></Route>
                            <Route path="/admin" element={<GhostieAdmin/>}></Route>
                            <Route path="*" element={<GhostieApp/>}></Route>
                        </Routes>
                    </BrowserRouter>
                </MobileView>        
            </>
        )
    }
    return <GhostieDesktop />
}