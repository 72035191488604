import React from 'react';
//import { BrowserRouter, Routes, Route } from 'react-router-dom';
//import './App2.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "@fontsource/open-sans"; // Defaults to weight 400.
import "@fontsource/inconsolata";
import './App3.css';
import './style.css';
import { GhostieIndex } from './ghostie/ghostie-index'

function App()
{
  return (
    <GhostieIndex/>
  )
}

export default App;
